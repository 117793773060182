import { useEffect, useState } from "react";
import { useLoaderData, useSearchParams, useLocation, useNavigate } from "react-router-dom";
import IconSearch from "../../images/icon-search.svg";
import {axios, dateDiff} from '../../utils';
import { toast } from "react-toastify";
import Modal from "../../Modal";
import Datepicker from "tailwind-datepicker-react";
import useUserStore from "../../stores/user";

const modalStyle = {
    content: {
        width: 500,
        height: 380,
        margin: 'auto',
        top: '-30%'
    }
}

const ODD = ({i, children})=><td className={`${i%2!=0&&'bg-td-odd'}`}>{children}</td>
const EVEN = ({i, children})=><td className={`${i%2!=0&&'bg-td-even'}`}>{children}</td>

const Search = ({value, onChange})=>{
    return <div>
        <input value={value} onChange={onChange} type="text" placeholder="Search" className="rounded-full h-[35px] w-72 pl-7 border-gray-400"/>
        <div className="relative -top-6 left-2 -mb-8 flex w-4 text-gray-300">
            <img src={IconSearch}/>
        </div>
    </div>
}

export default function Topups(){
    const topups = useLoaderData();
    const [searchParams, setSearchParams] = useSearchParams();
    const [sort, setSort] = useState(searchParams.get('sort') || 'date');
    const [type, setType] = useState(searchParams.get('type') || 'daily');
    const [search, setSearch] = useState('');
    const [inputTimeout, setInputTimeout] = useState();
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState();
    const location = useLocation();
    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());
    const [modalOpen, setModalOpen] = useState();
    const [filename, setFilename] = useState('Filename.xls');
	const [showStart, setShowStart] = useState(false);
    const [showEnd, setShowEnd] = useState(false);
    const user = useUserStore(state=>state.user);
    const navigate = useNavigate();

	const handleStartClose = (state) => {
		setShowStart(state)
	}
	const handleEndClose = (state) => {
		setShowEnd(state)
	}

    const onSort = (sort)=>{
        setSort(sort);
    }

    useEffect(() => {
        const type = searchParams.get('type')
        if(user
            && ((type=='daily' && !user['daily-topup-transactions']?.includes('view'))
            || (type=='monthly' && !user['month-to-date-topup-transactions']?.includes('view'))
            )
        ){
            toast.error("You are not allowed to view the page.");
            navigate('/')
        }
        setType(type)
    }, [location])

    useEffect(() => {
        clearTimeout(inputTimeout);
        setInputTimeout(setTimeout(() => {
            const type = searchParams.get('type')
            setSearchParams({sort, type, search})
        }, 500))
    }, [search])

    useEffect(()=>{
        if(topups){
            setLoading(true);
            setTimeout(()=>setLoading(false), 1000)
        }
    }, [topups])

    useEffect(() => {
        const type = searchParams.get('type')
        setSearchParams({sort, type, search, page})
    }, [sort, page]);

    const onExport = ()=>{
        if(dateDiff(new Date(start), new Date(end))>60){
            toast.error("Date range must be within 60 days only.")
            return;
        }
        axios.get(`reports/topups${window.location.search}&start=${start.getTime()}&end=${end.getTime()}&export=1`, {responseType:'blob'})
            .then((resp)=>{
                const href = URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${type} Topups Transactions.xls`); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }).catch(err=>{
                console.error(err)
                toast.error('Failed to download report');
            })
    }

    const onClickExport = ()=>{
        if(user
            && ((type=='daily' && !user['daily-topup-transactions']?.includes('view'))
                || (type=='monthly' && !user['month-to-date-topup-transactions']?.includes('view'))
            )
        ){
            toast.error("You are not allowed to do this action.");
            navigate('/')
        }
        setModalOpen(true)
    }
    
    return <>
        <div className="flex justify-between">
            <h1 className="font-semibold text-2xl ">{type == 'daily' ? 'DAILY' : 'MONTH TO DATE'} TOP UPS TRANSACTIONS</h1>
            <button onClick={onClickExport} target="_blank" className="bg-green rounded-full text-white py-1 px-10">EXPORT</button>
        </div>
        <div className="pagemenu bg-pagemenu rounded p-4 mt-7 flex justify-between">
            <ul className="cursor-pointer text-lg pt-1">
                <li>SORT:</li>
                <li onClick={()=>onSort('date')} className={`${sort=='date' ? 'underline' : 'text-gray-400'}`}>DATE</li>
                <li onClick={()=>onSort('branch')} className={`${sort=='branch' ? 'underline' : 'text-gray-400'}`}>BRANCH</li>
                <li onClick={()=>onSort('amount')} className={`${sort=='amount' ? 'underline' : 'text-gray-400'}`}>AMOUNT</li>
            </ul>
            <Search value={search} onChange={e=>setSearch(e.target.value)}/>
        </div>
        <table className="mt-4 table-auto w-[100%]">
            <thead>
                <tr>
                    <th>Transaction ID</th>
                    <th>Mobile Number</th>
                    <th>SKU</th>
                    <th>Amount</th>
                    <th>Order Status</th>
                    <th>Mode of Payment</th>
                    <th>Date</th>
                    <th>Timestamp</th>
                </tr>
            </thead>
            <tbody>
                {loading && <div className="loader" />}
                {loading === false && topups.rows.map((p, i)=><tr key={p.id}>
                    <ODD i={i}>{p.reference_number}</ODD>
                    <EVEN i={i}>{p.phone_number}</EVEN>
                    <ODD i={i}>{p.sku}</ODD>
                    <EVEN i={i}>{p.amount}</EVEN>
                    <EVEN i={i}>{p.status}</EVEN>
                    <ODD i={i}>{p.payment_method}</ODD>
                    <EVEN i={i}>{new Date(p.createdAt).toISOString().split('T')[0]}</EVEN>
                    <ODD i={i}>{p.timestamp}</ODD>
                </tr>)}
            </tbody>
        </table>
        <div className="flex justify-center pt-6">
            <ul className="pagination">
                {
                    Array(topups.totalPages).fill('').map((x,i)=> <li className={`cursor-pointer px-3 ${(i+1)==page && 'active rounded'}`} onClick={()=>setPage(i+1)}>{i+1}</li>)
                }
            </ul>
        </div>
        <Modal style={modalStyle} isOpen={modalOpen} onRequestClose={()=>setModalOpen(false)}>
            <p className="text-2xl text-center">Export</p>
            <div className="text-sm text-gray text-center mt-5">You can export maximum of 60 days worth of data</div>
            <div className="space-y-2">
                <div>Select Date</div>
                <div className="flex flex-between space-x-4">
                    <Datepicker options={{defaultDate:new Date()}} onChange={setStart} setShow={handleStartClose} show={showStart}/>
                    <Datepicker options={{defaultDate:new Date()}} onChange={setEnd} setShow={handleEndClose} show={showEnd}/>
                </div>
                <div>File Name</div>
                <input onChange={(e)=>setFilename(e.target.value)} value={filename} type="text" className="w-full rounded"/>
                <div className="space-y-2">
                    <button disabled={!filename} onClick={onExport} class="m-auto block bg-green rounded-full text-white py-1 px-10">
                        EXPORT
                    </button>
                    <button onClick={()=>setModalOpen(false)} className="m-auto block rounded-full border bg-green-outline py-1 px-10">
                        CLOSE
                    </button>
                </div>
            </div>
        </Modal>
    </>
}